import { useQueryClient } from '@tanstack/react-query';
import {
  OfferResponse,
  ServerPersesAppsOfferV9SchemasPollingRefreshMismatchError,
  useGetOfferId,
} from '@codegen/offerAPI';
import { isOnClient } from '@utils/helperUtils';
import { parseQueryString } from '@utils/queryUtils';
import { useSettings } from '../SettingsContext';
import { useStep } from '../StepContext';
import usePartnerRouter from './usePartnerRouter';

// This hook should be used to fetch the offer
// Calling onSuccess inside of there will result that each instance of the hook
// will call that callback, this might result in unexpected behaviour
const useGetOffer = ({
  onSuccess,
}: {
  onSuccess?: (data: OfferResponse) => void;
} = {}) => {
  const { push, query } = usePartnerRouter();
  const { currency } = useSettings();
  const queryClient = useQueryClient();
  const { firstBookingStepIndex, steps } = useStep();
  const {
    data: offer,
    isFetching: isFetchingOffer,
    isLoading: isOfferLoading,
    queryKey,
  } = useGetOfferId(
    parseQueryString(query.offerId),
    { curr: currency },
    {
      query: {
        retry: 1,
        enabled: isOnClient() && Boolean(query.offerId),
        onSuccess,
        // On error we acknowledge that this is an invalid offer and try to trigger an new useGetOffer
        onError: () => {
          push({
            pathname: steps[firstBookingStepIndex]?.route,
            query: { offerId: undefined },
            shallow: true,
          });
        },
      },
    },
  );

  const setOffer = (
    data: Partial<OfferResponse>,
    clearPriceMismatch = true,
  ) => {
    // We want to overwrite the errors, removing the price mismatch error when the offer is updated
    const errors = clearPriceMismatch
      ? offer?.errors.filter((error) => !('initial_price' in error))
      : offer?.errors;

    return queryClient.setQueryData(queryKey, () => {
      return { ...offer, ...data, errors };
    });
  };

  const priceMismatch = offer?.errors.find(
    (
      error,
    ): error is ServerPersesAppsOfferV9SchemasPollingRefreshMismatchError =>
      'initial_price' in error,
  );

  return {
    offer,
    requirements: offer?.requirements ?? [],
    isFetchingOffer,
    isOfferLoading,
    setOffer,
    priceMismatch,
  };
};

export default useGetOffer;
