import { createContext, ReactNode, useContext, useState } from 'react';
import NoCheckedBagsModal from '@ui/components/Offer/NoCheckedBagsModal';
import TrustPilotWidget from '@ui/components/TrustPilotWidget/TrustPilotWidget';
import AlyziaGroundHandlingInformationModal from '@web/components/Modals/AlyziaGroundHandlingInformationModal';
import DohopConnectRichInformationModal from '@web/components/Modals/DohopConnectRichInformationModal/DohopConnectRichInformationModal';
import ProtectGroupInformationModal from '@web/components/Modals/ProtectGroupInformationModal';
import { useConstants } from './ConstantContext';
import useDohopConnect from './hooks/useDohopConnect';
import useGetIcon from './hooks/useGetIcon';
import { useSettings } from './SettingsContext';

type ModalVariant =
  | 'dohop-connection-info'
  | 'alyzia'
  | 'no-checked-bags-info'
  | 'protect-group';

export type ModalOrchestrator = {
  hideModal: () => void;
  showModalFn: (modalVariant: ModalVariant) => () => void;
};

const context = createContext<ModalOrchestrator>({
  hideModal: () => {},
  showModalFn: () => () => {},
});

export default context;

export const ModalOrchestratorContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { locale } = useConstants();
  const { residency } = useSettings();

  const [modalVariant, setModalVariant] = useState<ModalVariant | null>(null);

  const getIcon = useGetIcon();

  const hideModal = () => setModalVariant(null);

  const showModalFn = (modalVariant: ModalVariant) => () =>
    setModalVariant(modalVariant);

  const { richModalBulletpoints, richModalTitle } = useDohopConnect();

  return (
    <context.Provider value={{ hideModal, showModalFn }}>
      {children}
      <DohopConnectRichInformationModal
        clockIcon={getIcon('clockIcon')}
        closeIcon={getIcon('closeIcon')}
        isOpen={modalVariant === 'dohop-connection-info'}
        onOpenChange={hideModal}
        planeIcon={getIcon('planeIcon')}
        richModalBulletpoints={richModalBulletpoints}
        richModalTitle={richModalTitle}
        trustPilotWidget={
          <TrustPilotWidget
            height="100px"
            localeString={`${locale}-${residency}`}
            widgetType="mini"
          />
        }
      />

      {modalVariant === 'alyzia' && (
        <AlyziaGroundHandlingInformationModal isOpen toggleModal={hideModal} />
      )}

      <ProtectGroupInformationModal
        isOpen={modalVariant === 'protect-group'}
        toggleModal={hideModal}
      />

      <NoCheckedBagsModal
        closeIcon={getIcon('closeIcon')}
        id="no-checked-bags-connection-modal-itinerary"
        isOpen={modalVariant === 'no-checked-bags-info'}
        locale={locale}
        onCloseModal={hideModal}
      />
    </context.Provider>
  );
};

export const useModalOrchestrator = () => useContext(context);
