import { ErrorInfo } from 'react';
import * as Sentry from '@sentry/nextjs';

export const isNumber = (n?: number | null | unknown) => {
  return Number(n) === n;
};

export const createAnArray = <T>(
  arraySize: number,
  value?: T,
): T[] | number[] =>
  typeof value !== 'undefined'
    ? // eslint-disable-next-line functional/immutable-data
      Array(arraySize).fill(value)
    : Array.from(Array(arraySize).keys());

export const getCookieValue = (
  cookieValue: string | undefined,
  cookieName: string,
  // eslint-disable-next-line functional/immutable-data
) => ('; ' + cookieValue).split(`; ${cookieName}=`).pop()?.split(';')[0];

export const partition = <T>(
  predicate: (val: T) => boolean,
  arr: Array<T>,
): [Array<T>, Array<T>] => {
  const partitioned: [Array<T>, Array<T>] = [[], []];
  arr.forEach((val: T) => {
    const partitionIndex: 0 | 1 = predicate(val) ? 0 : 1;
    // eslint-disable-next-line functional/immutable-data
    partitioned[partitionIndex].push(val);
  });

  return partitioned;
};

export const wrapArrayIfNeeded = <T>(maybeArray?: T[] | T) =>
  (!Array.isArray(maybeArray) && maybeArray ? [maybeArray] : maybeArray) as
    | T[]
    | undefined;

// Should check if two objects are deeply equal without using JSON.stringify
export const isDeepEqual = (a: unknown, b: unknown) => {
  if (a === b) {
    return true;
  }

  if (typeof a !== typeof b) {
    return false;
  }

  if (typeof a === 'object' && a && b) {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) {
      return false;
    }

    for (const key of aKeys) {
      if (
        !isDeepEqual(
          (a as { [key: string]: unknown })[key],
          (
            b as {
              [key: string]: unknown;
            }
          )[key],
        )
      ) {
        return false;
      }
    }

    return true;
  }

  return false;
};

export const isOnClient = () => typeof window !== 'undefined';

export const errorLogger = (error: Error, errorInfo: ErrorInfo) => {
  const componentError = new Error(error.message);
  /* eslint-disable functional/immutable-data */
  componentError.name = `React ErrorBoundary ${componentError.name}`;
  componentError.stack = errorInfo.componentStack || undefined;

  error.cause = error;
  Sentry.captureException(error);
  /* eslint-enable functional/immutable-data */
};

export const stripTrailishSlash = (url?: string | null) =>
  url?.replace(/\/$/, '');
