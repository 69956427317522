import { createContext, ReactNode, useContext } from 'react';
import {
  ExperimentVariantsFragment,
  useGetPartnerExperimentQuery,
} from '@codegen/cmsUtils';
import { Experiment } from '@web/types/experimentTypes';
import {
  getExperimentDataFromCMS,
  getExperimentKeys,
  getExperimentsKeysForRoutes,
} from '@web/utils/experimentUtils';
import { useConstants } from './ConstantContext';
import usePartnerRouter from './hooks/usePartnerRouter';

interface ExperimentData {
  experimentData?: ExperimentVariantsFragment;
  experiments?: Experiment[];
  isMovingPassengerStepExperiment?: boolean;
}

const context = createContext<ExperimentData>({});

const ExperimentProvider = ({ children }: { children: ReactNode }) => {
  const { locale, partner } = useConstants();
  const { route } = usePartnerRouter();

  const experiments = [
    ...getExperimentsKeysForRoutes(route),
    // Prioritize the experiment keys from the cookies
    ...getExperimentKeys(),
  ];

  const shouldRunQuery = experiments.some(
    (experiment) => experiment.variant !== 'current',
  );

  const { data } = useGetPartnerExperimentQuery(
    {
      partner,
      locale,
    },
    {
      enabled: shouldRunQuery,
    },
  );

  const experimentData = getExperimentDataFromCMS({
    partnerExperiment: data?.partner,
    experiments,
  });

  const isMovingPassengerStepExperiment = experiments.some(
    (exp) =>
      exp.name === 'experiment.2023q4-move-pax-step-v3' &&
      exp.variant === 'variantMovePaxStep',
  );

  return (
    <context.Provider
      value={{
        experiments,
        experimentData,
        isMovingPassengerStepExperiment,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default ExperimentProvider;

export const useExperiments = () => useContext(context);
