import { Theme } from '@emotion/react';
import {
  ThemeBackgroundColourProps,
  ThemeBorderProps,
  ThemeDisplayProp,
  ThemeFlexboxProps,
  ThemeLayoutProps,
  ThemeMarginProps,
  ThemeOpacityProps,
  ThemeOverflowProps,
  ThemePaddingProps,
  ThemePositionProps,
  ThemeTextColourProps,
  ThemeTextProps,
  ThemeFontWeightProps,
  ThemeVisibilityProps,
  ThemeCursorProps,
} from '@ui-v2/types/props';
import { retrieveThemeValues } from './retrieveThemeValues';

export const buildMargin = ({
  m,
  mb,
  ml,
  mr,
  mt,
  mx,
  my,
}: ThemeMarginProps) => ({
  marginTop: m ?? my ?? mt,
  marginBottom: m ?? my ?? mb,
  marginRight: m ?? mx ?? mr,
  marginLeft: m ?? mx ?? ml,
});

export const buildPadding = ({
  p,
  pb,
  pl,
  pr,
  pt,
  px,
  py,
}: ThemePaddingProps) => ({
  paddingTop: p ?? py ?? pt,
  paddingBottom: p ?? py ?? pb,
  paddingRight: p ?? px ?? pr,
  paddingLeft: p ?? px ?? pl,
});

export const buildColour = (props: ThemeTextColourProps, theme: Theme) => ({
  color: retrieveThemeValues(theme.colours, props.colour),
});

export const buildBackgroundColour = (
  props: ThemeBackgroundColourProps,
  theme: Theme,
) => ({
  background: retrieveThemeValues(theme.colours, props.bg),
});

export const buildDisplay = (props: { display?: ThemeDisplayProp }) => ({
  display: props.display,
});

export const buildFlexProps = (props: ThemeFlexboxProps) => ({
  alignContent: props.alignContent,
  alignItems: props.alignItems,
  alignSelf: props.alignSelf,
  flexBasis: props.flexBasis,
  flexDirection: props.flexDirection,
  flexGrow: props.flexGrow,
  flexShrink: props.flexShrink,
  flexWrap: props.flexWrap,
  justifyContent: props.justifyContent,
  justifyItems: props.justifyItems,
  justifySelf: props.justifySelf,
  gap: props.gap,
  rowGap: props.rowGap,
  columnGap: props.columnGap,
  flex: props.flex,
});

export const buildPositionProps = (
  props: ThemePositionProps,
  theme: Theme,
) => ({
  bottom: props.bottom,
  left: props.left,
  position: props.position,
  right: props.right,
  top: props.top,
  zIndex: retrieveThemeValues(theme.zIndices, props.zIndex),
  float: props.float,
});

export const buildLayoutProps = (props: ThemeLayoutProps, theme: Theme) => ({
  height: props.height,
  maxHeight: props.maxHeight,
  maxWidth:
    props.maxWidthCustom ?? retrieveThemeValues(theme.sizes, props.maxWidth),
  minHeight: props.minHeight,
  minWidth:
    props.minWidthCustom ?? retrieveThemeValues(theme.sizes, props.minWidth),
  width: props.widthCustom ?? retrieveThemeValues(theme.sizes, props.width),
});

export const buildBorderProps = (props: ThemeBorderProps, theme: Theme) => ({
  border: retrieveThemeValues(theme.borders, props.border),
  borderBottom: retrieveThemeValues(theme.borders, props.borderBottom),
  borderLeft: retrieveThemeValues(theme.borders, props.borderLeft),
  borderRadius: retrieveThemeValues(theme.shape, props.borderRadius),
  borderRight: retrieveThemeValues(theme.borders, props.borderRight),
  borderTop: retrieveThemeValues(theme.borders, props.borderTop),
  borderWidth: props.borderWidth,
  borderTopWidth: props.borderTopWidth,
  borderRightWidth: props.borderRightWidth,
  borderBottomWidth: props.borderBottomWidth,
  borderLeftWidth: props.borderLeftWidth,
  boxShadow: retrieveThemeValues(theme.shadows, props.boxShadow),
  borderTopStyle: props.borderTopStyle,
  borderRightStyle: props.borderRightStyle,
  borderBottomStyle: props.borderBottomStyle,
  borderLeftStyle: props.borderLeftStyle,
});

export const buildOverflowProps = (props: ThemeOverflowProps) => ({
  overflow: props.overflow,
  overflowY: props.overflowY,
  overflowX: props.overflowX,
  textOverflow: props.textOverflow,
  textWrap: props.textWrap,
});

export const buildTextProps = (props: ThemeTextProps) => ({
  textAlign: props.textAlign,
  whiteSpace: props.whiteSpace,
  wordWrap: props.wordWrap,
  pointerEvents: props.pointerEvents,
});

export const buildFontWeightProps = (props: ThemeFontWeightProps) => ({
  fontWeight: props.fontWeight,
});

export const buildOpacityProps = (props: ThemeOpacityProps) => ({
  opacity: props.opacity,
});

export const buildVisibilityProps = (props: ThemeVisibilityProps) => ({
  visibility: props.visibility,
});

export const buildCursorProps = (props: ThemeCursorProps) => ({
  cursor: props.cursor,
});
